<template>
  <div>
    <div class="boxes">
      <div class="connection-container">
        <ConnectionWindow :recaptcha="recaptcha"></ConnectionWindow>
      </div>
      <div class="register-container">
        <RegisterClientForm :recaptcha="recaptcha"></RegisterClientForm>
      </div>
    </div>
    <div class="fondBleuSecurise">
      <div
        style="
          display: flex;
          align-items: flex-end;
          margin-bottom: 10px;
          margin-right: 10px;
        "
      >
        <font-awesome-icon
          size="6x"
          style="margin-bottom: 15px"
          class="iconLock"
          color="white"
          icon="fa-solid fa-lock"
        />
        <div style="display: flex; align-items: flex-end">
          <font-awesome-icon
            size="2x"
            class="iconLock"
            color="white"
            icon="fa-solid fa-key"
          />
        </div>
      </div>
      <div class="accordeon">
        <div>
          <span class="title" v-b-toggle.collapse-1>
            <font-awesome-icon color="white" icon="fas fa-caret-right" />
            <h3>SÉCURITAIRE</h3>
          </span>
          <b-collapse id="collapse-1" class="mt-2">
            <p>
              Le site de Pro Ressources.ca ainsi que sa plateforme de prise de
              rendez-vous sont hébergés sur un serveur Google Cloud crypté et
              sécurisé. De plus, nous avons mis en place des logiciels de
              sécurité afin de contrer les attaques et les logiciels
              malveillants. La sécurité est une priorité chez Pro Ressources.ca
              !
            </p>
          </b-collapse>
        </div>
        <div>
          <span class="title" v-b-toggle.collapse-2>
            <font-awesome-icon color="white" icon="fas fa-caret-right" />
            <h3>CONFIDENTIEL</h3>
          </span>
          <b-collapse id="collapse-2" class="mt-2">
            <p>
              Les échanges avec nos professionnels demeurent strictement
              confidentiels et les informations à ton dossier seront classées et
              entreposées selon le code de déontologie des Ordres
              Professionnels. Notre plateforme de rendez-vous en
              vidéo-conférence Teams est cryptée et respecte aussi les normes
              québécoise (Loi 25 et LPRPSP) et canadiennes (PL C-11, LPRPDE et
              LPRPS) de sécurité et confidentialité en télésanté. De plus, nous
              avons paramétré les critères de sécurité et de confidentialité de
              la suite Microsoft Office 365 au maximum pour nous conformer à ces
              mêmes normes pour le stockage de données et les boîtes courriels
              de nos professionnels.
            </p>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectionWindow from "@/components/ConnectionWindow";
import RegisterClientForm from "@/components/RegisterClientForm";
import { load } from "recaptcha-v3";

export default {
  name: "Home",
  components: {
    ConnectionWindow,
    RegisterClientForm,
  },
  data() {
    return {
      recaptcha: null,
    };
  },
  created() {
    load(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY).then((recaptcha) => {
      this.recaptcha = recaptcha;
    });
  },
};
</script>

<style scoped>
.accordeon {
  margin-left: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.accordeon .title > h3 {
  display: inline-block;
  margin-left: 20px;
}
.accordeon div {
  margin-top: 10px;
}
.accordeon p {
  font-size: 1.2em;
}
.fondBleuSecurise {
  display: flex;
  background-color: #29689c;
  min-height: 50px;
  width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 10%;
  align-items: center;
}

.boxes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 75vw;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2%;
  margin-bottom: 2%;
}
.connection-container {
  width: 50%;
  height: fit-content;
  margin-right: 60px;
}
.register-container {
  height: fit-content;
  width: 50%;
  margin-left: 60px;
}
.connection-container,
.register-container {
  border: 1px solid black;
  border-radius: 3px;
  background-color: white;
}

.errorMessage {
  font-size: 14px;
  color: red;
}
@media screen and (max-width: 1800px) {
  .boxes {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .container,
  .register-container {
    min-height: 81vh;
  }
}
@media screen and (max-width: 1300px) {
  .accordeon p {
    font-size: 0.8rem;
  }
  .accordeon .title > h3 {
    font-size: 0.9rem;
  }
  .boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-right: auto;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: auto;
  }

  .connection-container {
    width: 90%;
    height: fit-content;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .register-container {
    height: fit-content;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
  }
}
/* @media only screen and (min-width: 500px) {
}

  @media only screen and (min-width: 600px) {
  }

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 992px) {

}
@media only screen and (min-width: 1200px) {

} */
</style>

<template>
  <AdminPageWrapper>
    <EntreprisePageWrapper />
  </AdminPageWrapper>
</template>

<script>
import AdminPageWrapper from "@/components/AdminPageWrapper.vue";
import EntreprisePageWrapper from "@/components/EntreprisePageWrapper.vue";

export default {
  name: "PageGestionEntreprise",
  components: {
    AdminPageWrapper,
    EntreprisePageWrapper,
  },
};
</script>

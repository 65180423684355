import Vue from "vue";
import Vuex from "vuex";
import admin from "./modules/admin.js";
import authentification from "./modules/authentification.js";
import category from "./modules/category.js";
import client from "./modules/client.js";
import consultations from "./modules/consultations.js";
import diplome from "./modules/diplome.js";
import entreprises from "./modules/entreprises.js";
import forfaits from "./modules/forfaits.js";
import langue from "./modules/langue.js";
import newsletter from "./modules/newsletter.js";
import ordre from "./modules/ordre.js";
import permis from "./modules/permis.js";
import professionnels from "./modules/professionnels.js";
import reservation from "./modules/reservation.js";
import specialite from "./modules/specialite.js";
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    authentification,
    professionnels,
    reservation,
    client,
    permis,
    diplome,
    specialite,
    langue,
    newsletter,
    admin,
    ordre,
    category,
    entreprises,
    forfaits,
    consultations,
  },
});

<template>
  <div>
    <p>
      S'il s'agit de votre <b>premier rendez-vous</b>, nous vous suggérons
      fortement une durée de <b>40 minutes</b>
    </p>
    <p v-if="professionnel.isTaxable">
      Ce professionnel est assujettis aux taxes applicable pour la province de
      Québec
    </p>
    Date: {{ dateChoisi }}<br />
    Longueur rendez-vous:
    <select :value="longueursRv.value" @change="longueurRvChanged">
      <option
        v-for="longueurRv in longueursRv.items"
        :key="longueurRv.nbMinutes"
        :value="longueurRv.nbMinutes"
      >
        {{ longueurRv.affichage }}
      </option>
    </select>
    <br />
    Heure début:
    <select :value="heuresDebut.value" @change="heureDebutChanged">
      <option
        v-for="heureDebut in heuresDebut.items"
        :key="heureDebut"
        :value="heureDebut"
      >
        {{ heureDebut }}
      </option>
    </select>
    <br />
    Heure fin: {{ heureFin }}
    <br />
    Sous-total: {{ sousTotal }}
    <br />
    <span v-if="professionnel.isTaxable">
      TPS: {{ tps }}
      <br />
      TVQ: {{ tvq }}
      <br />
    </span>
    Total: {{ total }}
    <br />
    Commentaires (500 caractères maximum ): <br />
    <textarea maxlength="500" v-model="commentaires" />
    <br />
    <b-button
      class="btnRV"
      :disabled="heuresDebut.items.length === 0"
      @click="soumettre"
      >Réserver</b-button
    >
    <b-button class="btnRV" @click="annuler">Annuler</b-button>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "ReservationRv",
  props: ["dateChoisi", "dispos", "professionnel"],
  data() {
    return {
      heureFin: "",
      heuresDebut: {
        items: [],
        value: "",
      },
      longueursRv: {
        items: [],
        value: 40,
      },
      commentaires: null,
      sousTotal: "",
      tps: "",
      tvq: "",
      total: "",
    };
  },
  methods: {
    soumettre() {
      history.pushState({}, document.title, window.location.href);
      var resumeRv = {
        datetime_start: this.dateChoisi + " " + this.heuresDebut.value,
        datetime_end: this.dateChoisi + " " + this.heureFin,
        professionnel: this.professionnel,
        commentaires: this.commentaires,
        longueursRv: this.longueursRv.value,
        taxable: this.professionnel.isTaxable,
        tps: this.tps,
        tvq: this.tvq,
        sousTotal: this.sousTotal,
        total: this.total,
      };
      this.$router.push({
        name: "Payment",
        params: { infoTransaction: resumeRv },
      });
    },
    longueurRvChanged(event) {
      this.longueursRv.value = event.target.value;
      this.assigneHeuresDispo();
      this.updateHeureFin();
      this.updateTotal();
    },
    heureDebutChanged(event) {
      this.heuresDebut.value = event.target.value;
      this.updateHeureFin();
      this.updateTotal();
    },
    updateHeureFin() {
      if (this.heuresDebut.value === "") {
        this.heureFin = "";
      } else {
        this.heureFin = moment(this.heuresDebut.value, "HH:mm:ss")
          .add(this.longueursRv.value, "minutes")
          .format("HH:mm:ss");
      }
    },
    updateTotal() {
      var sousTotal = parseFloat(
        parseFloat(this.professionnel.categoryTarif).toFixed(2) *
          parseFloat(this.longueursRv.value / 20)
      );
      var tps = 0;
      var tvq = 0;
      if (this.professionnel.isTaxable) {
        tps =
          (sousTotal * parseFloat(process.env.VUE_APP_TAXES_POURCENTAGE_TPS)) /
          100;
        tvq =
          (sousTotal * parseFloat(process.env.VUE_APP_TAXES_POURCENTAGE_TVQ)) /
          100;
      }
      this.sousTotal = sousTotal.toFixed(2) + "$";
      this.tps = tps.toFixed(2) + "$";
      this.tvq = tvq.toFixed(2) + "$";
      this.total = (sousTotal + tps + tvq).toFixed(2) + "$";
    },
    generateLongueursRv() {
      this.longueursRv.items = [];
      if (this.dispos.length > 0) {
        var tempsMinRv = 20;
        var tempsMaxRv = 120;
        var distancesEntreLongueurs = 20;
        let haveDefaultValue = false;
        for (
          var x = tempsMinRv;
          x <= tempsMaxRv;
          x += distancesEntreLongueurs
        ) {
          var heure = Math.floor(x / 60);
          var displayString = x + " minutes";
          if (heure > 0) {
            var heureString = " heure";
            if (heure > 1) {
              heureString = " heures";
            }
            displayString = heure + heureString;
            if (x % 60 !== 0) {
              displayString += " " + (parseInt(x) - 60 * heure) + " minutes";
            }
          }
          if (this.getSiLongueurRvPossible(x)) {
            if (x === this.longueursRv.value) {
              haveDefaultValue = true;
            }
            this.longueursRv.items.push({
              nbMinutes: x,
              affichage: displayString,
            });
          }
        }
        if (!haveDefaultValue && this.longueursRv.items.length > 0) {
          this.longueursRv.value = this.longueursRv.items[0].nbMinutes;
        }
      }
    },
    getSiLongueurRvPossible(longueurRvVoulue) {
      var siPossible = true;
      var siAujourdhui = this.dateChoisi === moment().format("YYYY-MM-DD");
      this.dispos.forEach((item) => {
        var startTime = moment(item["startTime"], "HH:mm:ss");
        var endTime = moment(item["endTime"], "HH:mm:ss");
        if (siAujourdhui) {
          var now = moment();
          if (startTime < now && endTime > now) {
            startTime = now;
          }
        }
        if (startTime.add(longueurRvVoulue, "minutes") > endTime) {
          siPossible = false;
        }
      });
      return siPossible;
    },
    assigneHeuresDispo() {
      var heuresDebutToutMele = [];
      var siAujourdhui = this.dateChoisi === moment().format("YYYY-MM-DD");
      this.dispos.forEach((item) => {
        var startTime = moment(item["startTime"], "HH:mm:ss");
        var endTime = moment(item["endTime"], "HH:mm:ss");
        if (siAujourdhui) {
          var now = moment();
          if (startTime < now) {
            var minutesAAjouter = 20 - (now.minute() % 20);
            startTime = now.add(minutesAAjouter, "minutes").set({ seconds: 0 });
          }
        }
        var timeRendu = startTime;
        if (this.longueursRv.value) {
          while (timeRendu <= endTime) {
            const debutRv = timeRendu.format("HH:mm:ss");
            const finRv = timeRendu.add(this.longueursRv.value, "minutes");
            if (finRv.format("HH:mm:ss") <= endTime.format("HH:mm:ss")) {
              if (!heuresDebutToutMele.includes(debutRv)) {
                heuresDebutToutMele.push(debutRv);
              }
            }
            timeRendu = finRv;
          }
        }
      });
      this.heuresDebut.items = [];
      heuresDebutToutMele.sort();
      heuresDebutToutMele.forEach((item) => {
        this.heuresDebut.items.push(item);
      });
      if (this.heuresDebut.items.length > 0) {
        this.heuresDebut.value = this.heuresDebut.items[0];
      }
    },
    annuler() {
      this.$bvModal.hide("modal-rv");
    },
  },
  created() {
    this.generateLongueursRv();
    this.assigneHeuresDispo();
    this.updateHeureFin();
    this.updateTotal();
  },
};
</script>

<style scoped>
.btnRV {
  margin-right: 5px;
}
.btnRV:hover {
  background-color: #29689c !important;
}
</style>

<template>
  <div>
    Date début
    <b-form-input type="date" name="dateStart" v-model="dateStart" /> Heure
    début
    <b-form-timepicker locale="fr-ca" name="timeStart" v-model="timeStart" />
    <br />
    <br />
    Date Fin <b-form-input type="date" name="dateEnd" v-model="dateEnd" /> Heure
    Fin <b-form-timepicker locale="fr-ca" name="timeEnd" v-model="timeEnd" />
    <br />
    <div v-if="erreur !== ''" class="erreurContainer">
      {{ erreur }}<br /><br />
    </div>
    <b-button class="buttonSoumettre" type="submit" @click="creerVacances()"
      >Créer</b-button
    >
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "VacancesSelection",
  data() {
    return {
      dateStart: null,
      timeStart: "08:00",
      dateEnd: null,
      timeEnd: "16:00",
      erreur: "",
    };
  },
  methods: {
    creerVacances() {
      var vacances = {};
      this.erreur = "";
      vacances["datetime_start"] =
        moment(this.dateStart).format("YYYY-MM-DD") +
        " " +
        moment(this.timeStart, "HH:mm").format("HH:mm:ss");
      vacances["datetime_end"] =
        moment(this.dateEnd).format("YYYY-MM-DD") +
        " " +
        moment(this.timeEnd, "HH:mm").format("HH:mm:ss");
      vacances["type"] = "vacance";
      if (
        moment(vacances["datetime_start"]) < moment(vacances["datetime_end"])
      ) {
        this.$bvModal.show("modal-chargement");
        this.$store
          .dispatch("creerVacancesProfessionnel", vacances)
          .then((response) => {
            this.$bvModal.hide("modal-chargement");
            location.reload();
          });
      } else {
        this.erreur =
          "La date et l'heure de début doit être avant la date et l'heure de fin";
      }
    },
  },
};
</script>

<style scoped>
.erreurContainer {
  color: red;
}
</style>

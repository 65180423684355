<template>
  <div class="container">
    <h1 id="PRFO">Mon profil</h1>
    <form class="centered" v-on:submit.prevent="soumettreUser">
      <table id="userTable" v-if="user">
        <tr>
          <td>Prénom</td>
          <td>
            <b-form-input
              required
              type="text"
              name="first_name"
              placeholder="Prénom"
              v-model="user.first_name"
            />
          </td>
        </tr>
        <tr>
          <td>Nom de famille</td>
          <td>
            <b-form-input
              required
              type="text"
              name="last_name"
              placeholder="Nom de famille"
              v-model="user.last_name"
            />
          </td>
        </tr>
        <tr v-if="siProfessionnel">
          <td>Catégorie</td>
          <td>
            <select v-model="user.category">
              <option
                v-for="option in listeCategories"
                v-bind:key="option.id"
                v-bind:value="option.id"
              >
                {{ option.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td>Téléphone</td>
          <td>
            <b-form-input
              required
              type="tel"
              name="phone"
              placeholder="Téléphone"
              v-model="user.phone"
            />
          </td>
        </tr>
        <tr v-if="siAdmin">
          <td>Email</td>
          <td>
            <b-form-input
              :disabled="siProfessionnel"
              type="email"
              name="email"
              placeholder="Email"
              v-model="user.email"
            />
          </td>
        </tr>
        <tr>
          <td>Adresse</td>
          <td>
            <b-form-input
              required
              type="text"
              name="address"
              placeholder="Adresse"
              v-model="user.address"
            />
          </td>
        </tr>
        <tr>
          <td>Code Postal</td>
          <td>
            <b-form-input
              required
              type="text"
              name="zip_code"
              placeholder="Code Postal"
              v-model="user.zip_code"
            />
          </td>
        </tr>
        <tr>
          <td>Ville</td>
          <td>
            <b-form-input
              required
              type="text"
              name="city"
              placeholder="Ville"
              v-model="user.city"
            />
          </td>
        </tr>
        <tr>
          <td>Province</td>
          <td>
            <b-form-input
              required
              type="text"
              name="province"
              placeholder="Province"
              v-model="user.province"
            />
          </td>
        </tr>
        <tr>
          <td>Pays</td>
          <td>
            <b-form-input
              required
              type="text"
              name="country"
              placeholder="Pays"
              v-model="user.country"
            />
          </td>
        </tr>
        <tr v-if="siProfessionnel">
          <td>Description</td>
          <td>
            <b-form-textarea
              type="text"
              name="description"
              placeholder="Description"
              v-model="user.description"
            />
          </td>
        </tr>
        <tr v-if="siProfessionnel">
          <td>Photo</td>
          <td>
            <b-form-file
              type="text"
              accept="image/jpeg, image/png, image/gif"
              name="photo"
              placeholder="photo"
              v-model="photoFile"
            />
          </td>
        </tr>
        <tr>
          <td>Changement mot de passe?</td>
          <td><input type="checkbox" v-model="changeMotDePasse" /></td>
        </tr>
        <tr v-if="changeMotDePasse">
          <td>Mot de passe</td>
          <td>
            <b-form-input
              :required="!$route.params.id"
              type="password"
              name="password"
              placeholder="Mot de passe"
              v-model="user.password"
            />
          </td>
        </tr>
        <tr v-if="changeMotDePasse">
          <td>Confirmation de mot de passe</td>
          <td>
            <b-form-input
              :required="!$route.params.id"
              type="password"
              name="c_password"
              placeholder="Confirmation de mot de passe"
              v-model="user.c_password"
            /><br />
          </td>
        </tr>
      </table>
      <br />
      <span
        style="color: red"
        v-for="erreur in listeErreurs"
        v-bind:key="erreur"
        >{{ erreur }}<br
      /></span>
      <div
        class="centered containingTables"
        v-if="user && this.user.role.name === 'Professionnel'"
      >
        <div>
          <table>
            <tr>
              <td>Langues:</td>
            </tr>
            <tr
              class="tableItem"
              v-for="langue in user.langues"
              v-bind:key="langue.langue_id"
            >
              <td><input type="text" v-model="langue.langue" /></td>
              <td>
                <input
                  type="button"
                  @click="
                    supprimerLangue(langue, langue.siNouveau, user.langues)
                  "
                  value="supprimer"
                />
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="button"
                  @click="ajouterItem('langues')"
                  value="ajouter langue"
                />
              </td>
            </tr>
          </table>
          <br />
        </div>
        <div>
          <table>
            <tr>
              <td>Spécialités:</td>
            </tr>
            <tr
              class="tableItem"
              v-for="specialite in user.specialites"
              v-bind:key="specialite.specialite_id"
            >
              <td><input type="text" v-model="specialite.nom" /></td>
              <td>
                <input
                  type="button"
                  @click="
                    supprimerSpecialite(
                      specialite,
                      specialite.siNouveau,
                      user.specialites
                    )
                  "
                  value="supprimer"
                />
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="button"
                  @click="ajouterItem('specialites')"
                  value="ajouter spécialite"
                />
              </td>
            </tr>
          </table>
          <br />
        </div>
        <div>
          <table>
            <tr>
              <td>Diplôme:</td>
            </tr>
            <tr
              class="tableItem item3"
              v-for="diplome in user.diplomes"
              v-bind:key="diplome.diplome_id"
            >
              <td><input type="text" v-model="diplome.titre_diplome" /></td>
              <td>
                Années:<br /><input
                  type="text"
                  v-model="diplome.annee_diplome"
                />
              </td>
              <td class="left-centered">
                <input
                  type="button"
                  @click="
                    supprimerDiplome(diplome, diplome.siNouveau, user.diplomes)
                  "
                  value="supprimer"
                />
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="button"
                  @click="ajouterItem('diplomes')"
                  value="ajouter diplôme"
                />
              </td>
            </tr>
          </table>
          <br />
        </div>
        <div>
          <div class="attributecontainer">
            <div>Permis:</div>
            <div
              class="attributecontainer-item"
              v-for="permi in user.permis"
              v-bind:key="permi.permi_id"
            >
              <div class="attributecontainer-item-field">
                <span>Titre</span>
                <input type="text" v-model="permi.titre_permis" />
              </div>
              <div class="attributecontainer-item-field">
                <span>Numéro de permis</span>
                <input type="text" v-model="permi.noPermis" />
              </div>
              <div class="attributecontainer-item-field">
                <template v-if="!permi.siNouveau">
                  <span>Lien permis</span>
                  <a target="_blank" :href="lienStorage + permi.lien">
                    <img class="imgPermi" src="@/assets/download.png" />
                  </a>
                </template>
                <template v-else>
                  <span>Fichier permis</span>
                  <b-form-file
                    placeholder="permis"
                    type="text"
                    v-model="permi.fichier"
                  />
                </template>
              </div>
              <div class="attributecontainer-item-field">
                <span>Prioritaire</span>
                <input type="checkbox" v-model="permi.prioritaire" />
              </div>
              <div class="attributecontainer-item-field">
                <input
                  type="button"
                  @click="supprimerPermis(permi, permi.siNouveau, user.permis)"
                  value="supprimer"
                />
              </div>
            </div>
            <input
              type="button"
              class="attributecontainer-addButton"
              @click="ajouterItem('permis')"
              value="ajouter permis"
            />
          </div>
          <br />
        </div>
      </div>
      <b-button id="btnSendProfile" class="buttonSoumettre" type="submit"
        >Soumettre</b-button
      >
    </form>
    <div v-if="user && siProfessionnel">
      <br />
      <div>Lien Microsoft</div>
      <div class="successMessage" v-if="siLieMicrosoft">
        Le compte est lié a microsoft
      </div>
      <div class="errorMessage" v-else>Le compte n'est pas lié a microsoft</div>
    </div>
    <div v-if="user">
      <br />
      <span>Infolettre</span><br />
      <a
        href="javascript:void(0);"
        v-if="user.ifSubToNewsletter === false"
        @click="setAuthUserNewsFeed(true)"
        >S'inscrire à la liste de courriels《outils et conseils gratuits》</a
      >
      <a href="javascript:void(0);" v-else @click="setAuthUserNewsFeed(false)"
        >Se désinscrire de la liste de courriel 《outils et conseil gratuit》</a
      >
    </div>
    <div v-if="user && !siAdmin">
      <br />
      <span
        >Par mesure de sécurité : Pour modifier votre courriel,
        <a :href="lienForum + '/professionnel/'">contactez-nous.</a></span
      >
    </div>
    <b-modal
      id="modal-chargement"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
      centered
      hide-footer
      title="Chargement"
    >
      Chargement...
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModificationProfil",
  data() {
    return {
      user: null,
      ancienUser: null,
      photoFile: null,
      changeMotDePasse: false,
      listeErreurs: [],
      lienStorage: process.env.VUE_APP_BACKEND_STORAGE_URL,
    };
  },
  computed: {
    lienForum() {
      return process.env.VUE_APP_FORUM_LIEN;
    },
    listeCategories() {
      return this.$store.getters.listeCategories;
    },
    siProfessionnel() {
      if (this.user && this.user.role) {
        return this.user.role.name === "Professionnel";
      } else {
        return null;
      }
    },
    siAdmin() {
      if (this.user && this.user.role) {
        return this.user.role.name === "Admin";
      } else {
        return null;
      }
    },
    siLieMicrosoft() {
      if (this.user) {
        return this.user.hasBeenAddedToMicrosoftGraph;
      } else {
        return false;
      }
    },
  },
  methods: {
    ajouterItem(listeName) {
      if (!this.user[listeName]) {
        this.user[listeName] = [];
      }
      this.user[listeName].push({ siNouveau: true, fichier: null });
      this.$forceUpdate();
    },
    sauvegarderPermis(item, siNouveau) {
      var body = item;
      var lien = "sauvegarderPermisExistant";
      if (siNouveau) {
        lien = "sauvegarderNouveauPermis";
      }
      this.$bvModal.show("modal-chargement");
      this.$store.dispatch(lien, body).then((response) => {
        this.refreshUser();
      });
    },
    supprimerPermis(item, siNouveau, liste) {
      if (siNouveau) {
        liste.splice(liste.indexOf(item), 1);
      } else {
        var body = item;
        this.$bvModal.show("modal-chargement");
        this.$store.dispatch("supprimerPermis", body).then((response) => {
          liste.splice(liste.indexOf(item), 1);
          this.$bvModal.hide("modal-chargement");
        });
      }
    },
    sauvegarderDiplome(item, siNouveau) {
      var body = item;
      var lien = "sauvegarderDiplomeExistant";
      if (siNouveau) {
        lien = "sauvegarderNouveauDiplome";
      }
      this.$bvModal.show("modal-chargement");
      this.$store.dispatch(lien, body).then((response) => {
        this.refreshUser();
      });
    },
    supprimerDiplome(item, siNouveau, liste) {
      if (siNouveau) {
        liste.splice(liste.indexOf(item), 1);
      } else {
        var body = item;
        this.$bvModal.show("modal-chargement");
        this.$store.dispatch("supprimerDiplome", body).then((response) => {
          liste.splice(liste.indexOf(item), 1);
          this.$bvModal.hide("modal-chargement");
        });
      }
    },
    sauvegarderSpecialite(item, siNouveau) {
      var body = item;
      var lien = "sauvegarderSpecialiteExistant";
      if (siNouveau) {
        lien = "sauvegarderNouveauSpecialite";
      }
      this.$bvModal.show("modal-chargement");
      this.$store.dispatch(lien, body).then((response) => {
        this.refreshUser();
      });
    },
    supprimerSpecialite(item, siNouveau, liste) {
      if (siNouveau) {
        liste.splice(liste.indexOf(item), 1);
      } else {
        var body = item;
        this.$bvModal.show("modal-chargement");
        this.$store.dispatch("supprimerSpecialite", body).then((response) => {
          liste.splice(liste.indexOf(item), 1);
          this.$bvModal.hide("modal-chargement");
        });
      }
    },
    sauvegarderLangue(item, siNouveau) {
      var body = item;
      var lien = "sauvegarderLangueExistant";
      if (siNouveau) {
        lien = "sauvegarderNouveauLangue";
      }
      this.$bvModal.show("modal-chargement");
      this.$store.dispatch(lien, body).then((response) => {
        this.refreshUser();
      });
    },
    supprimerLangue(item, siNouveau, liste) {
      if (siNouveau) {
        liste.splice(liste.indexOf(item), 1);
      } else {
        var body = item;
        this.$bvModal.show("modal-chargement");
        this.$store.dispatch("supprimerLangue", body).then((response) => {
          liste.splice(liste.indexOf(item), 1);
          this.$bvModal.hide("modal-chargement");
        });
      }
    },
    genererRequeteChangementUser() {
      const formData = new FormData();
      for (var key in this.user) {
        if (
          this.user[key] !== this.ancienUser[key] &&
          !Array.isArray(this.user[key]) &&
          key !== "role"
        ) {
          formData[key] = this.user[key];
        }
      }
      if (this.photoFile != null) {
        formData["photo"] = this.photoFile;
      }
      if (this.user["specialites"]) {
        formData["specialites"] = JSON.stringify(this.user["specialites"]);
      }
      if (this.user["diplomes"]) {
        formData["diplomes"] = JSON.stringify(this.user["diplomes"]);
      }
      if (this.user["langues"]) {
        formData["langues"] = JSON.stringify(this.user["langues"]);
      }
      if (this.user["permis"]) {
        for (var x = 0; x < this.user["permis"].length; x++) {
          this.user["permis"][x]["ordre"] = x;
          formData["permisFichier_" + x] = this.user["permis"][x]["fichier"];
        }
        formData["permis"] = JSON.stringify(this.user["permis"]);
      }
      return formData;
    },
    refreshUser() {
      var userAvant = this.user;
      this.$store.dispatch("requestUserDetails").then((res) => {
        this.user = JSON.parse(JSON.stringify(res["data"]));
        this.ancienUser = JSON.parse(JSON.stringify(res["data"]));
        if (this.siProfessionnel && userAvant !== null) {
          if (userAvant["specialites"] !== undefined) {
            userAvant["specialites"].forEach((element) => {
              if (element["siNouveau"]) {
                this.user["specialites"].push(element);
              }
            });
          }
          if (userAvant["diplomes"] !== undefined) {
            userAvant["diplomes"].forEach((element) => {
              if (element["siNouveau"]) {
                this.user["diplomes"].push(element);
              }
            });
          }
          if (userAvant["langues"] !== undefined) {
            userAvant["langues"].forEach((element) => {
              if (element["siNouveau"]) {
                this.user["langues"].push(element);
              }
            });
          }
          if (userAvant["permis"] !== undefined) {
            userAvant["permis"].forEach((element) => {
              if (element["siNouveau"]) {
                this.user["permis"].push(element);
              }
            });
          }
        }
        this.$bvModal.hide("modal-chargement");
      });
    },
    soumettreUser() {
      this.listeErreurs = [];
      if (!this.user.first_name) {
        this.listeErreurs.push("Le prénom est requis");
      }
      if (!this.user.last_name) {
        this.listeErreurs.push("Le nom de famille est requis");
      }
      if (!this.user.phone) {
        this.listeErreurs.push("le numéro de téléphonne est requis");
      }
      if (!this.user.city) {
        this.listeErreurs.push("La ville est requise");
      }
      if (!this.user.address) {
        this.listeErreurs.push("L'adresse est requise");
      }
      if (!this.user.country) {
        this.listeErreurs.push("Le pays est requis");
      }
      if (!this.user.province) {
        this.listeErreurs.push("La province est requise");
      }
      if (this.changeMotDePasse) {
        if (!this.user.password) {
          this.listeErreurs.push("Le mot de passe est requis");
        } else if (!this.user.c_password) {
          this.listeErreurs.push("La confirmation de mot de passe est requise");
        } else if (this.user.password !== this.user.c_password) {
          this.listeErreurs.push(
            "La confirmation de mot de passe n'est pas similaire au mot de passe"
          );
        }
      }
      if (!this.user.zip_code) {
        this.listeErreurs.push("Le code postal est requis");
      }
      if (this.siProfessionnel && !this.user.category) {
        this.listeErreurs.push("La catégorie est requise");
      }
      if (this.listeErreurs.length === 0) {
        var bodyRequete = this.genererRequeteChangementUser();
        var requeteInfo = {
          body: bodyRequete,
          userId: this.user.id,
        };
        this.$bvModal.show("modal-chargement");
        this.$store
          .dispatch("modifUser", requeteInfo)
          .then((response) => {
            if (response.status === 200) {
              if (this.user["specialites"] !== undefined) {
                this.user["specialites"].forEach((element) => {
                  if (element["siNouveau"]) {
                    element["siNouveau"] = false;
                  }
                });
              }
              if (this.user["diplomes"] !== undefined) {
                this.user["diplomes"].forEach((element) => {
                  if (element["siNouveau"]) {
                    element["siNouveau"] = false;
                  }
                });
              }
              if (this.user["langues"] !== undefined) {
                this.user["langues"].forEach((element) => {
                  if (element["siNouveau"]) {
                    element["siNouveau"] = false;
                  }
                });
              }
              if (this.user["permis"] !== undefined) {
                this.user["permis"].forEach((element) => {
                  if (element["siNouveau"]) {
                    element["siNouveau"] = false;
                  }
                });
              }
              this.refreshUser();
              this.$toast.open(
                "Votre profil a été modifié avec succès",
                "Success Alert",
                { timeOut: 5000 }
              );
            } else {
              this.$bvModal.hide("modal-chargement");
              this.$toast.open("Une erreur est survenue", "error", {
                timeOut: 5000,
              });
            }
          })
          .catch((erreur) => {
            this.$bvModal.hide("modal-chargement");
            if (erreur.response.data === "email taken") {
              this.$toast.error(
                "l'adresse email est déjà utilisée pour un autre compte",
                { timeOut: 5000 }
              );
            } else {
              this.$toast.error(
                "Une erreur est survenue, veuillez contacter les administrateurs",
                { duration: 5000 }
              );
            }
          });
      }
    },
    setAuthUserNewsFeed(ifWantSub) {
      this.$bvModal.show("modal-chargement");
      this.$store
        .dispatch("setAuthUserNewsFeed", ifWantSub)
        .then(() => {
          this.user.ifSubToNewsletter = ifWantSub;
          this.$bvModal.hide("modal-chargement");
        })
        .catch(() => {
          this.$bvModal.hide("modal-chargement");
          this.$toast.error("une erreur est survenue");
        });
    },
  },
  mounted() {
    this.$bvModal.show("modal-chargement");
    this.refreshUser();
    this.$store.dispatch("fetchCategories");
  },
};
</script>

<style scoped>
#btnSendProfile {
  margin-bottom: 20px;
}
.successMessage {
  background: green;
  padding: 0.5rem 1rem;
  color: white;
}
.errorMessage {
  padding: 0.5rem 1rem;
  background: red;
  color: white;
}
.imgPermi {
  width: 4vh;
  height: 4vh;
}
img {
  width: 100px;
  height: 100px;
}
#PRFO {
  text-align: center !important;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: red;
  padding: 2.5% 5% !important;
}
.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: fit-content;
}
input[type="button"]:hover {
  background-color: #235c8f !important;
}
input[type="button"] {
  font-family: "Open Sans" !important;
  background-color: #ffc90e !important;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%) !important;
  color: white !important;
  font-weight: 700 !important;
  border-radius: 6px !important;
  transition: all 0.3s !important;
  border: none !important;
  text-transform: uppercase !important;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
}
.imageBtn {
  background-color: #ffc90e !important;
  width: 40px;
  height: 40px;
}
.imageBtn > img {
  width: 40px;
  height: 40px;
}
.container {
  background: white;
  border: 1px solid black;
  padding: 5px;
  margin-top: 20px;
}
input[type="checkbox"] {
  padding-right: 2%;
}
input[type="text"],
input[type="tel"],
input[type="email"],
textarea,
select {
  width: 100%;
}
td {
  text-align: start;
}
.custom-file {
  width: 100%;
}
tr:not(.item3, .item4) td {
  max-width: 50%;
}
.item3 td .left-centered {
  text-align: left;
}
.item3 td {
  vertical-align: bottom;
  width: 30%;
}
.item4 td .left-centered {
  text-align: left;
}
.item4 td {
  vertical-align: bottom;
  /* width: 25%; */
}
.item4 td input[type="button"],
.imageBtn .item4 td input[type="text"] {
  width: 100% !important;
}
.b-form-file {
  text-align: left !important;
}
.attributecontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.attributecontainer-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 2px solid #29689c;
  border-radius: 5px;
  padding: 8px;
  padding-bottom: 0;
}
.attributecontainer-item-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}
.attributecontainer-item-field span {
  margin-right: 5px;
  white-space: nowrap;
}
.attributecontainer-addButton {
  margin-top: 5px;
}
</style>

import axios from "axios";

const state = {
  entreprises: [],
  entreprise: {
    forfait: {},
  },
};

const getters = {
  entreprises: (state) => state.entreprises,
  entreprise: (state) => state.entreprise,
};

const mutations = {
  setEntreprises(state, entreprises) {
    state.entreprises = entreprises;
  },
  setEntreprise(state, entreprise) {
    state.entreprise = { ...entreprise, forfait: entreprise.forfait || {} };
  },
  removeEntreprise(state, id) {
    state.entreprises = state.entreprises.filter(
      (entreprise) => entreprise.id !== id
    );
  },
};

const actions = {
  getAllEntreprises({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/entreprises",
        method: "get",
        baseURL: process.env.VUE_APP_BACKEND_URL,
      })
        .then((response) => {
          commit("setEntreprises", response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getEntreprise({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/entreprises/" + id,
        method: "get",
        baseURL: process.env.VUE_APP_BACKEND_URL,
      })
        .then((response) => {
          commit("setEntreprise", response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteEntreprise({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/entreprises/" + id,
        method: "delete",
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: {
          confirm: true,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          commit("removeEntreprise", id);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  registerOrUpdateEntreprise({ commit }, entreprise) {
    return new Promise((resolve, reject) => {
      const isNew = entreprise.id === undefined;
      const url =
        "/" + (isNew ? "entreprises" : "entreprises/" + entreprise.id);
      const method = isNew ? "post" : "put";
      axios({
        url: url,
        method: method,
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: entreprise,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (isNew) {
            commit("setEntreprises", [...state.entreprises, response.data]);
          } else {
            commit("setEntreprise", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import axios from "axios";

const state = {
  forfaits: [],
  forfait: null,
};

const getters = {
  forfaits: (state) => state.forfaits,
  forfait: (state) => state.forfait,
};

const mutations = {
  setForfaits(state, forfaits) {
    state.forfaits = forfaits;
  },
  setForfait(state, forfait) {
    state.forfait = forfait;
  },
  removeForfait(state, id) {
    state.forfaits = state.forfaits.filter((forfait) => forfait.id !== id);
  },
};

const actions = {
  getAllForfaits({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/forfaits",
        method: "get",
        baseURL: process.env.VUE_APP_BACKEND_URL,
      })
        .then((response) => {
          commit("setForfaits", response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getForfait({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/forfaits/" + id,
        method: "get",
        baseURL: process.env.VUE_APP_BACKEND_URL,
      })
        .then((response) => {
          commit("setForfait", response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteForfait({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: "/forfaits/" + id,
        method: "delete",
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: {
          confirm: true,
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          commit("removeForfait", id);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  registerOrUpdateForfait({ commit }, forfait) {
    return new Promise((resolve, reject) => {
      const isNew = forfait.id === undefined;
      const url = "/" + (isNew ? "forfaits" : "forfaits/" + forfait.id);
      const method = isNew ? "post" : "put";

      axios({
        url: url,
        method: method,
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: forfait,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          if (isNew) {
            commit("setForfaits", [...state.forfaits, response.data]);
          } else {
            commit("setForfait", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

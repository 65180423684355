<template>
  <div class="entreprise-container">
    <div class="entreprise-linkbar">
      <router-link
        v-for="link in linkList"
        :key="link.url"
        :to="`/admin/entreprise/${$route.params.id}/${link.url}`"
      >
        {{ link.displayText }}
      </router-link>
    </div>
    <div class="entreprise-content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "EntreprisePageWrapper",
  data() {
    return {
      linkList: [
        {
          displayText: "Tableau de bord",
          url: "stats",
        },
        {
          displayText: "Liste des employés",
          url: "employees",
        },
        {
          displayText: "Forfait",
          url: "forfait",
        },
        {
          displayText: "Profil",
          url: "profil",
        },
      ],
    };
  },
};
</script>

<style scoped>
.entreprise-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.entreprise-content {
  width: 100%;
}
.entreprise-linkbar {
  background-color: #29689c;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}
.entreprise-linkbar a {
  color: white;
  padding: 0.5rem 1rem;
  flex-basis: auto;
}
.entreprise-linkbar a:hover {
  text-decoration: none;
}
</style>

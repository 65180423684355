<template>
  <div class="boxes">
    <div id="containeur">
      <div class="validationText">
        <div v-if="errors.length">
          <p class="errorMessage" v-for="error in errors" :key="error">
            {{ error }}
          </p>
        </div>
        <h3>Validation de votre identité</h3>
        <p>
          Afin de valider votre identité nous vous avons envoyé un code de 8
          chiffres et lettres par courriel ou message texte (SMS). Veuillez
          entrer le code ici pour vous connecter en toute sécurité.
        </p>
      </div>
      <form class="codeForm" v-on:submit.prevent="sendCode">
        <div class="codeContainer">
          <b-form-input
            ref="inputCode"
            required
            name="code"
            placeholder="RM32Z43J"
            type="text"
            v-model="code"
          />
        </div>
        <b-button id="sendCodeButton" type="submit">Envoyez</b-button>
      </form>
      <b-modal
        id="modal-sendingCode"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        :hide-header-close="true"
        centered
        hide-footer
        title="Validation"
      >
        Validation en cours...
      </b-modal>
    </div>
  </div>
</template>

<script>
import { load } from "recaptcha-v3";

export default {
  name: "MultiFactorAuth",
  data() {
    return {
      recaptcha: null,
      code: "",
      errors: [],
    };
  },
  created() {
    load(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY).then((recaptcha) => {
      this.recaptcha = recaptcha;
    });
  },
  methods: {
    sendCode() {
      this.recaptcha.execute("login").then((token) => {
        this.errors = [];
        this.$bvModal.show("modal-sendingCode");
        this.$store
          .dispatch("validate2FaCode", {
            code: this.code,
            token: token,
            userId: sessionStorage.getItem("userId"),
          })
          .then(() => {
            sessionStorage.removeItem("userId");
            this.$store
              .dispatch("requestUserDetails") // get user details
              .then((res) => {
                this.$bvModal.hide("modal-sendingCode");
                this.$router.push("/");
                location.reload();
              });
          })
          .catch(() => {
            this.errors.push("Le code de validation est incorrect.");
            this.errors.push("Vous recevrez un nouveau code sous peu.");
            this.$bvModal.hide("modal-sendingCode");
            this.code = "";
            setTimeout(() => {
              this.$refs.inputCode.focus();
            }, 1000);
          });
      });
    },
  },
};
</script>

<style scoped>
.errorMessage {
  font-size: 14px;
  color: red;
}
.codeForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#sendCodeButton {
  margin-top: 50px;
}
.validationText {
  margin-bottom: 60px;
  width: 60%;
}
.codeContainer {
  width: 50%;
}
.boxes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 75vw;
  margin-right: auto;
  margin-left: auto;
}
#containeur {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  min-height: 60vh;
  background-color: white;
  width: 70%;
  padding-bottom: 10px;
}
#containeur:hover {
  box-shadow: 6px 6px 6px 6px rgb(75, 73, 73);
}

.errorMessage {
  font-size: 14px;
  color: red;
}
@media screen and (max-width: 1800px) {
  .boxes {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
}
@media screen and (max-width: 1300px) {
  .boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
  }
  #containeur {
    width: 100%;
  }
}
</style>

<template>
  <div class="content is_table">
    <table class="table">
      <thead>
        <tr>
          <th v-for="header in headers" :key="header.value">
            {{ header.text }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.ID">
          <td v-for="header in headers" :key="header.value">
            {{ item[header.value] }}
          </td>
          <td>
            <div class="table is_actions">
              <!-- Slot for action buttons -->
              <slot name="item-actions" :item="item"></slot>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "ElementList",
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
};
</script>

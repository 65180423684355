<template>
  <div>
    <form id="payment-form" class="mt-5">
      <div class="resumeAchats">
        <h2>Résumé de la transaction</h2>
        <table>
          <tr>
            <td>Rendez-vous avec:</td>
            <td>{{ fullProfessionnelName }}</td>
          </tr>
          <tr>
            <td>Début:</td>
            <td>{{ infoTransaction.datetime_start }}</td>
          </tr>
          <tr>
            <td>Fin:</td>
            <td>{{ infoTransaction.datetime_end }}</td>
          </tr>
          <tr>
            <td>Tarif du professionnel:</td>
            <td>
              {{ infoTransaction.professionnel.categoryTarif }} $ par 20 minutes
            </td>
          </tr>
          <tr>
            <td>Longueur du Rendez-vous:</td>
            <td>{{ infoTransaction.longueursRv }} minutes</td>
          </tr>
          <tr v-if="infoTransaction.taxable">
            <td>Sous-total:</td>
            <td>{{ infoTransaction.sousTotal }}</td>
          </tr>
          <tr v-if="infoTransaction.taxable">
            <td>TPS:</td>
            <td>{{ infoTransaction.tps }}</td>
          </tr>
          <tr v-if="infoTransaction.taxable">
            <td>TVQ:</td>
            <td>{{ infoTransaction.tvq }}</td>
          </tr>
          <tr>
            <td>Total:</td>
            <td>{{ infoTransaction.total }}</td>
          </tr>
        </table>
      </div>
      <div class="form-row mt-4">
        <label for="card-element">
          <h3>Crédit ou débit</h3>
        </label>
        <div class="cardContainer">
          <div ref="card" id="card-element">
            <!-- A Stripe Element will be inserted here. -->
          </div>
          <!-- Used to display form errors. -->
          <div id="card-errors" role="alert"></div>
        </div>
      </div>
      <button id="btnSubmitPayment" type="submit" class="mb-5">
        Confirmer la réservation
      </button>
    </form>
    <b-modal
      id="modal-chargement"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
      centered
      hide-footer
      title="Chargement"
    >
      Veuillez patienter pendant que nous validons votre réservation. Cela peut
      prendre quelques instants....
    </b-modal>
    <b-modal
      id="modal-fini"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
      centered
      hide-footer
      title="Succès"
    >
      Votre transaction a été effectuée avec succès!<br />
      <b-button class="btnLike" href="/calendrier"
        >RETOUR À MES RENDEZ-VOUS</b-button
      >
    </b-modal>
    <b-modal
      id="modal-nonDispo"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
      centered
      hide-footer
      title="Erreur"
    >
      La plage horaire que vous avez sélectionné n'est plus disponible! <br />
      <b-button class="btnLike" href="/professionnels"
        >RETOUR AU REGISTRE</b-button
      >
    </b-modal>
    <b-modal
      id="modal-rvDejaLa"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
      centered
      hide-footer
      title="Erreur"
    >
      Vous avez déjà un rendez-vous dans cette plage horaire! <br />
      <b-button class="btnLike" href="/professionnels"
        >RETOUR AU REGISTRE</b-button
      >
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Payment",
  props: ["infoTransaction"],
  data() {
    return {
      stripe: window.Stripe(process.env.VUE_APP_STRIPE_KEY),
      elements: null,
      card: null,
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };
  },
  computed: {
    fullProfessionnelName() {
      return (
        this.infoTransaction.professionnel.first_name +
        " " +
        this.infoTransaction.professionnel.last_name
      );
    },
    /* getPrix () {
      var tarifCategorie = parseFloat(this.infoTransaction.professionnel.categoryTarif).toFixed(2)
      var tempsTotal = parseFloat(this.infoTransaction.longueursRv).toFixed(2)
      var nbDe20Min = parseFloat(tempsTotal / 20).toFixed(2)
      var total = parseFloat(tarifCategorie * nbDe20Min)
      if (this.infoTransaction.professionnel.isTaxable) {
        total = parseFloat(total + (total * process.env.VUE_APP_TAXES_POURCENTAGE / 100))
      }
      return total.toFixed(2) + '$'
    } */
  },
  methods: {
    createMeeting(stripeTokenId) {
      this.$bvModal.show("modal-chargement");
      const formData = new FormData();

      formData.append(
        "professionnel_id",
        this.infoTransaction.professionnel.id
      );
      formData.append("datetime_start", this.infoTransaction.datetime_start);
      formData.append("datetime_end", this.infoTransaction.datetime_end);

      if (this.infoTransaction.commentaires !== undefined) {
        formData.append("commentaires", this.infoTransaction.commentaires);
      }
      formData.append("stripe_token", stripeTokenId);

      const object = {};
      formData.forEach((value, key) => {
        object[key] = value;
      });
      const json = object;

      this.$store
        .dispatch("addReservation", json)
        .then((response) => {
          if (response.status === 200) {
            this.$bvModal.hide("modal-chargement");
            this.$bvModal.show("modal-fini");
          } else {
            const errorElement = document.getElementById("card-errors");
            errorElement.textContent =
              "Une erreur s'est produite, veuillez communiquer avec les administrateurs du site.";
          }
        })
        .catch((error) => {
          const messageErreur = error.response.data;
          this.$bvModal.hide("modal-chargement");
          switch (messageErreur) {
            case "non disponible": {
              this.$bvModal.show("modal-nonDispo");
              break;
            }
            case "rv client bloque": {
              this.$bvModal.show("modal-rvDejaLa");
              break;
            }
            case "card error": {
              // this.$toast.error('Il y a eu une erreur avec votre carte', { duration: 5000 })
              const errorElement = document.getElementById("card-errors");
              errorElement.textContent =
                "Il y a eu une erreur avec votre carte";
              break;
            }
            case "mail server error": {
              // this.$toast.error('Il y a un problème avec le serveur mail', { duration: 5000 })
              const errorElement = document.getElementById("card-errors");
              errorElement.textContent =
                "Il y a un problème avec le serveur mail";
              break;
            }
            default: {
              // this.$toast.error('Une erreur est survenue, veuillez contacter les administrateurs', { duration: 5000 })
              const errorElement = document.getElementById("card-errors");
              errorElement.textContent =
                "Une erreur est survenue, veuillez contacter les administrateurs";
              break;
            }
          }
        });
    },
    submitForm(event) {
      event.preventDefault();
      this.stripe.createToken(this.card).then((result) => {
        if (result.error) {
          // Inform the user if there was an error.
          const errorElement = document.getElementById("card-errors");
          errorElement.textContent = result.error.message;
        } else {
          this.createMeeting(result.token.id);
        }
      });
    },
  },
  created() {
    if (this.infoTransaction === undefined) {
      this.$router.push("/");
    }
  },
  mounted() {
    this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
    this.elements = this.stripe.elements();
    this.card = this.elements.create("card", { style: this.style });
    this.card.mount("#card-element");

    this.card.on("change", function (event) {
      var displayError = document.getElementById("card-errors");
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    });

    const form = document.getElementById("payment-form");
    form.addEventListener("submit", (event) => this.submitForm(event));
  },
};
</script>

<style scoped>
#card-errors {
  color: red;
  margin-bottom: 30px;
}
.cardContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.btnLike {
  font-family: "Open Sans" !important;
  background-color: #ffc90e !important;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%) !important;
  color: white !important;
  font-weight: 700 !important;
  border-radius: 6px !important;
  transition: all 0.3s !important;
  border: none !important;
  text-transform: uppercase !important;
}
label {
  display: block;
  width: 100%;
  text-align: center;
}
#btnSubmitPayment {
  background-color: #ffc90e !important;
  border-radius: 5px;
  border-color: black;
  border: black solid 1px;
}
#btnSubmitPayment:hover {
  background-color: #29689c !important;
}
.resumeAchats h2 {
  color: #235c8f;
  text-align: left;
  font-family: Roboto;
  font-weight: 900;
  margin-bottom: 20px;
}

.resumeAchats {
  border: 1px solid black;
  padding: 1vw;
  background-color: white;
  display: inline-block;
}
.resumeAchats table {
  text-align: left;
}
.resumeAchats table td {
  padding-right: 20px;
}
.StripeElement {
  box-sizing: border-box;

  height: 40px;
  padding-right: 20px;
  padding-left: 20px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 500px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
@media screen and (max-width: 1200px) {
  .resumeAchats {
    width: 95%;
    margin: auto;
  }

  .StripeElement {
    width: 80vw;
  }
}
</style>

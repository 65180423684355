var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',[_c('section',{attrs:{"id":"header-section-1"}},[_c('div',{staticClass:"header-section-content"},[_vm._m(0),_c('div',{attrs:{"id":"section-button-header"}},[_vm._m(1),_vm._m(2),_vm._m(3),_c('a',{attrs:{"href":"https://vimeo.com/proressourcesca"}},[_c('font-awesome-icon',{staticClass:"icons",attrs:{"color":"white","icon":"fa-brands fa-vimeo"}})],1),_c('a',{ref:"profilLink",attrs:{"href":"/profil"},on:{"mouseover":_vm.profilMouseOver,"mouseout":_vm.profilMouseOut}},[_c('img',{attrs:{"border":"0","alt":"userAccount","src":require("@/assets/user-alt.png")}})])])])]),_c('section',{attrs:{"id":"header-section-2"}},[_c('div',{staticClass:"header-section-content"},[_c('NavigationBar')],1)]),_c('div',{ref:"profilInfoContainer",staticClass:"profilInfoContainer",style:({
      top: _vm.profilInfoPosition.y + 'px',
      left: _vm.profilInfoPosition.x + 'px',
    })},[_vm._v(" Mon Profil ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"section-info-header"}},[_c('a',{attrs:{"href":"tel:4187401730"}},[_c('i',{staticClass:"fa fa-phone",attrs:{"aria-hidden":"true"}}),_vm._v(" +1 (418) 740-1730")]),_c('a',{attrs:{"href":"mailto:contact@proressources.ca"}},[_c('i',{staticClass:"fa fa-envelope",attrs:{"aria-hidden":"true"}}),_vm._v(" contact@proressources.ca")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://facebook.com/proressources.ca"}},[_c('img',{attrs:{"border":"0","alt":"facebook icon","src":require("@/assets/facebookIcon.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.instagram.com/proressource.ca/"}},[_c('img',{attrs:{"border":"0","alt":"instagram icon","src":require("@/assets/instagram.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.linkedin.com/company/pro-ressources-ca/"}},[_c('img',{attrs:{"border":"0","alt":"linkedin icon","src":require("@/assets/linkedIn.png")}})])
}]

export { render, staticRenderFns }
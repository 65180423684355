<template>
  <tr id="ficheContainer">
    <td class="photoElem">
      <img
        class="photoProf"
        :src="getStorageUrl() + this.professionnel.photo"
        v-if="!siDefautImage"
        @error="mettreDefautPhoto"
      />
      <img
        class="photoProf"
        src="@/assets/blankProfilePicture.png"
        v-if="siDefautImage"
      />
    </td>
    <td>
      <div class="infoContainer">
        <div class="text">
          <h3 id="titreProf">
            {{ professionnel.first_name + " " + professionnel.last_name }}
          </h3>
          <h4 id="titreCategorie">{{ professionnel.categoryName }}</h4>
        </div>
        <div class="btnsContainer">
          <b-button id="buttonProfil" type="submit" @click="voirProfil"
            >VOIR PROFIL</b-button
          >
          <b-button
            v-if="!siProfessionnel"
            id="buttonRendezvous"
            type="submit"
            @click="ouvrirRvModal"
            >RENDEZ-VOUS</b-button
          >
        </div>
      </div>
    </td>
    <hr />
  </tr>
</template>

<script>
export default {
  name: "FicheProfessionnel",
  props: ["professionnel"],
  data() {
    return {
      siDefautImage: false,
    };
  },
  computed: {
    siProfessionnel() {
      return sessionStorage.getItem("role") === "2";
    },
  },
  methods: {
    voirProfil() {
      history.pushState({}, document.title, window.location.href);
      this.$router.push({
        name: "infoProfessionnel",
        params: { id: this.professionnel.id },
      });
    },
    ouvrirRvModal() {
      this.$parent.toggleRendezVousModal(this.professionnel);
    },
    mettreDefautPhoto() {
      this.siDefautImage = true;
    },
    getStorageUrl() {
      var storage = process.env.VUE_APP_BACKEND_STORAGE_URL;
      return storage;
    },
  },
};
</script>

<style scoped>
.btnsContainer {
  display: flex;
  flex-direction: row;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.photoProf {
  width: 10vw;
  height: 10vw;
  margin-right: 1vw;
}
#ficheContainer {
  margin-bottom: 50px;
  display: block;
}

.text {
  text-align: left;
}

.text p {
  font-family: roboto;
  font-size: 12px;
}
#titreProf {
  color: black;
  font-size: 22px;
  font-family: roboto;
  text-align: left;
}
#titreCategorie {
  color: #235c8f;
  font-size: 16px;
  font-family: roboto;
  text-align: left;
}
#buttonProfil {
  background-color: #ffc90e;
  margin-right: 1vw;
  color: #235c8f;
  font-family: roboto;
  font-size: 12px;
  border-radius: 30px;
  margin-bottom: 1vw;
  border: 0px;
  outline: 0px;
}
#buttonProfil:hover,
#buttonRendezvous:hover {
  background-color: #235c8f !important;
  color: white;
}
#buttonRendezvous {
  background-color: #235c8f;
  color: white;
  font-family: roboto;
  font-size: 12px;
  border-radius: 30px;
  border: 0px;
  outline: 0px;
  margin-bottom: 1vw;
}

hr {
  border-width: 5px;
  border-color: #235c8f;
  opacity: 0.1;
  margin-top: 50px;
}

@media only screen and (max-width: 1024px) {
  #buttonProfil,
  #buttonRendezvous {
    font-size: 12px;
    margin-right: 1vw;
    margin-bottom: 0vw;
  }

  .text p {
    font-family: roboto;
    font-size: 14px;
  }
  .photoProf {
    width: 30vw;
    height: 30vw;
    margin-right: 4vw;
  }

  #titreProf {
    font-size: 24px;
  }

  #titreCategorie {
    font-size: 18px;
  }
}
</style>

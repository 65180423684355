<template>
  <div class="content is_wrap">
    <h1>Liste des employés</h1>
    <div class="content is_actions">
      <b-input
        class="employee-searchbar"
        type="search"
        v-model="searchQuery"
        @input="updateEmployeesTableData"
        placeholder="Rechercher"
      />
      <div class="buttons is_inline">
        <button @click="">Créer</button>
        <button @click="addEmployees">Ajouter</button>
        <button @click="importEmployees">Importer</button>
        <button @click="">Exporter</button>
      </div>
    </div>

    <div class="content-middle">
      <b-table ref="employeesTable" :fields="employeesHeaders" :items="users">
        <template #cell(edit)="data">
          <b-button
            :to="{
              name: 'pageGestionCompte',
              params: { id: data.item.id },
            }"
          >
            Modifier
          </b-button>
        </template>
        <template #cell(delete)="data">
          <b-button variant="danger" @click="removeEmployee(data.item.id)">
            Supprimer
          </b-button>
        </template>
      </b-table>
    </div>

    <!-- New Modal for User List -->
    <div v-if="showUserListModal" class="usersModal is_wrap">
      <div class="usersModal is_content">
        <div class="usersModal is_header">
          <h1>Ajouter un ou des employés</h1>
          <button class="usersButton" @click="addSelectedUsers">Ajouter</button>
          <span class="close" @click="closeUserListModal">&times;</span>
        </div>
        <div class="usersModal-body">
          <b-input
            class="user-searchbar"
            type="search"
            v-model="userListSearchQuery"
            @input="updateUsersTableData"
            placeholder="Rechercher"
          />
          <b-table
            ref="usersTable"
            :fields="modalFields"
            :items="myProvider"
            :current-page="currentPage"
            :per-page="perPage"
            selectable
            select-mode="multi"
            @row-selected="onRowSelected"
          >
            <template #cell(selected)="{ rowSelected, selectRow }">
              <b-form-checkbox
                :checked="rowSelected"
                @change="selectRow($event)"
              ></b-form-checkbox>
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <!-- Modal for Importing CSV -->
    <div v-if="showImportModal" class="usersModal is_wrap">
      <div class="usersModal is_content">
        <div class="usersModal is_header">
          <h1>Importer des employés</h1>
          <span class="close" @click="closeImportModal">&times;</span>
        </div>
        <div class="form is_content">
          <input type="file" @change="handleFileUpload" accept=".xlsx" />
        </div>
        <div>
          <button class="importButton" @click="uploadXlsxFile">Importer</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ElementForm from "./ElementForm.vue";
import ElementList from "./ElementList.vue";

export default {
  name: "EntrepriseUsers",
  components: {
    ElementList,
    ElementForm,
  },
  data() {
    return {
      searchQuery: "",
      userListSearchQuery: "",
      users: [],
      showUserListModal: false,
      showImportModal: false,
      xlsxFile: null,
      modalTitle: "",
      submitButtonText: "",
      currentItem: {},
      allUsers: [],
      selectedUsers: [],
      importedUsers: [],
      currentPage: 1,
      perPage: 10,
      employeesHeaders: [
        { key: "first_name", label: "Prénom" },
        { key: "last_name", label: "Nom" },
        { key: "address", label: "Adresse" },
        { key: "zip_code", label: "Code Postal" },
        { key: "city", label: "Ville" },
        { key: "province", label: "Province" },
        { key: "country", label: "Pays" },
        { key: "phone", label: "Téléphone" },
        { key: "email", label: "Email" },
        { key: "edit", label: "Modifier" },
        { key: "delete", label: "Supprimer" },
      ],
      modalFields: [
        { key: "selected", label: "" },
        { key: "first_name", label: "Prénom" },
        { key: "last_name", label: "Nom" },
        { key: "phone", label: "Téléphone" },
        { key: "address", label: "Adresse" },
      ],
    };
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("fetchUsersByEntreprise", this.$route.params.id)
          .then((response) => {
            this.users = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            console.error(
              "Erreur lors de la récupération des utilisateurs:",
              error
            );
            reject(error);
          });
      });
    },
    handleFileUpload(event) {
      this.xlsxFile = event.target.files[0];
    },
    uploadXlsxFile() {
      if (!this.xlsxFile) {
        this.$toast.warning("Veuillez sélectionner un fichier XLSX d'abord.", {
          duration: 5000,
        });
        return;
      }

      this.$store
        .dispatch("usersImport", {
          file: this.xlsxFile,
          entrepriseId: this.$route.params.id,
        })
        .then((response) => {
          this.$toast.success(response.message, { duration: 5000 });
          this.showImportModal = false;
          this.fetchUsers();
        })
        .catch((error) => {
          console.error(
            "Erreur lors de l'importation des utilisateurs:",
            error
          );
          this.$toast.error(
            "Une erreur s'est produite lors de l'importation des utilisateurs.",
            { duration: 5000 }
          );
        });
    },
    addEmployees() {
      this.selectedUsers = [];
      this.myProvider();
      this.showUserListModal = true;
    },
    importEmployees() {
      this.showImportModal = true;
    },
    removeEmployee(id) {
      this.$bvModal
        .msgBoxConfirm("Voulez-vous vraiment supprimer l'employé?", {
          title: "Supprimer",
          cancelTitle: "Non",
          okTitle: "Oui",
        })
        .then((canDelete) => {
          if (canDelete) {
            this.$store
              .dispatch("removeEmployeeFromEntreprise", {
                userId: id,
                entrepriseId: this.$route.params.id,
              })
              .then((response) => {
                this.$toast.success(response.message, { duration: 5000 });
                this.fetchUsers();
              })
              .catch((error) => {
                console.error("Error removing user from entreprise:", error);
                this.$toast.error(
                  response.message ||
                    "Une erreur s'est produite lors de la suppression de l'employé.",
                  { duration: 5000 }
                );
              });
          } else {
            this.$toast.error("Suppression annulée", { duration: 5000 });
          }
        })
        .catch((error) => {
          this.$toast.error(
            error.response.data.message ||
              "Erreur lors de la suppression de l'employé",
            { duration: 5000 }
          );
        });
    },
    closeUserListModal() {
      this.showUserListModal = false;
      this.userListSearchQuery = "";
    },
    closeImportModal() {
      this.showImportModal = false;
    },
    addSelectedUsers() {
      return new Promise((resolve, reject) => {
        if (this.selectedUsers.length === 0) {
          this.$toast.warning(
            "Veuillez sélectionner au moins un utilisateur.",
            {
              duration: 5000,
            }
          );
          reject(new Error("Aucun utilisateur sélectionné"));
          return;
        }

        this.$store
          .dispatch("addSelectedUsersToEntreprise", {
            entrepriseId: this.$route.params.id,
            users: this.selectedUsers,
          })
          .then((response) => {
            this.$toast.success(response.message, { duration: 5000 });
            this.fetchUsers();
            this.showUserListModal = false;
            this.selectedUsers = [];
            resolve();
          })
          .catch((error) => {
            console.error("Error adding users to entreprise:", error);
            this.$toast.error(
              response.message ||
                "Une erreur s'est produite lors de l'ajout des utilisateurs.",
              { duration: 5000 }
            );
            reject(error);
          });
      });
    },
    onRowSelected(items, index, event) {
      this.selectedUsers = items.map((item) => item.id);
      this.selectAll = this.selectedUsers.length === this.allUsers.length;
    },
    updateEmployeesTableData() {
      this.$refs.employeesTable.refresh();
    },
    updateUsersTableData() {
      this.$refs.usersTable.refresh();
    },
    myProvider(ctx) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("fetchUsersClient")
          .then((response) => {
            this.allUsers = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            console.error(
              "Erreur lors de la récupération des utilisateurs:",
              error
            );
            reject(error);
          });
      });
    },
  },
};
</script>

<style scoped>
/* CONTENT */
.content.is_wrap {
  background-color: white;
  border-radius: 10px;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}

.content.is_actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 3rem;
  margin-bottom: 3rem;
  margin-top: 2rem;
  border-top: 1px solid gray;
}

.content.is_table {
  min-width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

.content.is_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.content-middle {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 400px;
  align-items: flex-start;
  background-color: white;
  overflow-y: auto;
}

.employee-searchbar {
  min-width: 200px;
  width: 25%;
  max-width: 100%;
  margin-left: 10px;
}

/* Buttons */
.buttons.is_inline {
  display: inline-flex;
  gap: 1rem;
  height: 2.5rem;
}

/* USERS MODAL */
.usersModal.is_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.usersModal.is_content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
}

.usersModal.is_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid gray;
  background-color: white;
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
}

.usersModal-body {
  margin-top: 20px;
  width: 1200px;
  max-width: 100%;
  max-height: 400px;
  overflow-y: auto;
}

.usersModal input {
  margin-bottom: 0.5rem;
}

.usersButton,
.importButton {
  padding: 0.5rem;
}

.user-searchbar {
  min-width: 200px;
  width: 25%;
  max-width: 100%;
  margin-left: 10px;
}

.form.is_content {
  padding: 2rem;
}

.close {
  cursor: pointer;
  font-size: 64px;
  margin-left: 1rem;
  margin-bottom: 1.2rem;
  color: darkred;
  &:hover {
    color: red;
  }
}
</style>

// sourcery skip: avoid-using-var
import axios from "axios";

const state = {};

const getters = {
  isLoggedIn: (state) => sessionStorage.getItem("token") !== null,
  isAdmin: (state) => sessionStorage.getItem("role") === 1,
};

const actions = {
  login({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/login", credentials)
        .then(({ data }) => {
          if (!data.waiting2Fa) {
            sessionStorage.setItem("token", data.token);
            axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
            resolve();
          } else {
            var error = new Error("2fa");
            error.is2Fa = true;
            sessionStorage.setItem("userId", data.userId);
            reject(error);
          }
        })
        .catch((exception) => {
          var error = new Error(exception.response.data);
          error.is2Fa = false;
          reject(error);
        });
    });
  },
  validate2FaCode({ commit }, request) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/validate2faCode", request)
        .then(({ data }) => {
          sessionStorage.setItem("token", data.token);
          axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
          resolve();
        })
        .catch((exception) => {
          var error = new Error(exception.message);
          reject(new Error(error));
        });
    });
  },
  saveTaxableForUser({ commit }, request) {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/adminSetTaxableForUser",
          method: "post",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: request,
        })
        .then(() => {
          resolve();
        })
        .catch((exception) => {
          var error = new Error(exception.message);
          reject(new Error(error));
        });
    });
  },
  save2FaForUser({ commit }, request) {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/adminSet2FaForUser",
          method: "post",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: request,
        })
        .then(() => {
          resolve();
        })
        .catch((exception) => {
          var error = new Error(exception.message);
          reject(new Error(error));
        });
    });
  },
  saveBlockedForUser({ commit }, request) {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/adminSetBlockedForUser",
          method: "post",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          data: request,
        })
        .then(() => {
          resolve();
        })
        .catch((exception) => {
          var error = new Error(exception.message);
          reject(new Error(error));
        });
    });
  },
  logout({ commit }) {
    commit("clearUserData");
  },
  modifUser({ commit }, requete) {
    return new Promise((resolve, reject) => {
      var body = requete.body;
      var bodyFormData = new FormData();
      var x;
      for (x in body) {
        if (body.photo !== x) {
          bodyFormData.append(x, body[x]);
        }
      }
      if (body.photo) {
        bodyFormData.append("photo", body.photo);
      }
      axios
        .request({
          url: "/users/" + requete.userId,
          method: "post",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
          data: bodyFormData,
        })
        .then((response) => {
          commit("setUserInfo", response.data);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  requestUserDetails({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/details",
          method: "get",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          commit("setUserInfo", response.data);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  requestSpecificUserDetails({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/details/" + userId,
          method: "get",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  requestUserWebexToken({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/getSelfWebexId",
          method: "get",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  forgotPassword(context, email) {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/requestForgottenPassword",
          method: "post",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          data: email,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendNewPassword(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/changeForgottenPassword",
          method: "post",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          data: credentials,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  clearUserData() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("role");
  },
  setUserInfo(state, userInfo) {
    sessionStorage.setItem("role", userInfo.role_id);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

// sourcery skip: avoid-using-var
import axios from "axios";

const actions = {
  addReservation(context, newProduct) {
    var token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/reservations",
          method: "post",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: newProduct,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getRendezVousPris(context) {
    var token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/meetings",
          method: "get",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDisposProfessionnel(context, infoRequete) {
    var token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/dispos",
          method: "post",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: infoRequete,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteReservation(context, idReservation) {
    var token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/deleteMeeting/" + idReservation,
          method: "delete",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteVacance(context, idVacance) {
    var token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/deleteVacances/" + idVacance,
          method: "delete",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteDispoUnique(context, idDispo) {
    var token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/deleteDispo/" + idDispo,
          method: "delete",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteDispoHebdo(context, idDispo) {
    var token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/deleteDispoHebdo/" + idDispo,
          method: "delete",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getVacancesProfessionnel(context, infoRequete) {
    var token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/getVacances",
          method: "post",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: infoRequete,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  creerDispoFin(context, infoRequete) {
    var token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/creerDispoFin",
          method: "post",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: infoRequete,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  creerDisposProfessionnel(context, infoRequete) {
    var token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/creerDispo",
          method: "post",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: infoRequete,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  creerVacancesProfessionnel(context, infoRequete) {
    var token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/addVacances",
          method: "post",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: infoRequete,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getIfHasNewMeeting(context, infoRequete) {
    var token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: "/getIfHasNewMeeting",
          method: "post",
          baseURL: process.env.VUE_APP_BACKEND_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: infoRequete,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  actions,
};

<template>
  <AdminPageWrapper>
    <div class="content">
      <div class="content-top">
        <div class="content-top-top">
          <span>Recherche</span>
          <b-input
            class="top-searchbar"
            type="search"
            v-model="query"
            @input="updateTableData"
          />
        </div>
        <div class="content-top-bottom">
          <b-button :to="{ name: 'entrepriseSettings' }">
            Ajouter une entreprise
          </b-button>
        </div>
      </div>
      <div class="content-middle">
        <b-table ref="entreprisesTable" :fields="fields" :items="myProvider">
          <template #cell(edit)="data">
            <b-button
              :to="{
                name: 'entrepriseStats',
                params: { id: data.item.id },
              }"
            >
              Gestion
            </b-button>
          </template>
          <template #cell(delete)="data">
            <b-button variant="danger" @click="deleteItem(data.item.id)">
              Supprimer
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
  </AdminPageWrapper>
</template>

<script>
import AdminPageWrapper from "@/components/AdminPageWrapper.vue";

export default {
  name: "ListeEntreprises",
  components: {
    AdminPageWrapper,
  },
  data() {
    return {
      isEntreprisesLoaded: false,
      forfaits: [],
      entreprises: [],
      query: "",
      fields: [
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: "Entreprise", sortable: true },
        { key: "first_name", label: "Prénom" },
        { key: "last_name", label: "Nom" },
        { key: "role", label: "Rôle" },
        { key: "phone", label: "Téléphone" },
        { key: "poste", label: "Poste" },
        { key: "email", label: "Courriel" },
        { key: "forfaits.name", label: "Forfait", sortable: true },
        { key: "employees", label: "Nombre d'employés" },
        { key: "edit", label: "Gestion" },
        { key: "delete", label: "Supprimer" },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store
        .dispatch("entreprises/getAllEntreprises")
        .then((entreprises) => {
          this.entreprises = entreprises;
          this.isEntreprisesLoaded = true;
          this.updateTableData();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    updateTableData() {
      this.$refs.entreprisesTable.refresh();
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm("Voulez-vous vraiment supprimer l'entreprise?", {
          title: "Supprimer",
          cancelTitle: "Non",
          okTitle: "Oui",
        })
        .then((canDelete) => {
          if (canDelete) {
            this.$store
              .dispatch("entreprises/deleteEntreprise", id)
              .then(() => {
                this.fetchData();
                this.$toast.success("Entreprise supprimée avec succès", {
                  duration: 5000,
                });
              });
          } else {
            this.$toast.error("Suppression annulée", { duration: 5000 });
          }
        })
        .catch((error) => {
          this.$toast.error(
            error.response.data.message ||
              "Erreur lors de la suppression du forfait",
            { duration: 5000 }
          );
        });
    },
    myProvider(ctx, callback) {
      const whenEntreprisesLoaded = () => {
        const query = this.query;
        const entreprisesWithQuery = this.entreprises.filter(
          (element) =>
            element.name.includes(query) ||
            element.first_name.includes(query) ||
            element.last_name.includes(query) ||
            element.role.includes(query) ||
            element.phone.includes(query) ||
            element.poste.includes(query) ||
            element.email.includes(query) ||
            element.forfait.name.includes(query) ||
            element.employees.toString().includes(query) ||
            query === ""
        );
        callback(entreprisesWithQuery);
      };
      if (!this.isEntreprisesLoaded) {
        this.fetchData().then(() => {
          whenEntreprisesLoaded();
        });
      } else {
        whenEntreprisesLoaded();
      }

      return null;
    },
  },
};
</script>

<style scoped>
.content-middle {
  display: flex;
  flex-direction: column;
  width: 99vw;
  align-items: flex-start;
  background-color: white;
}
.content-top-top {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.content-top-bottom {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.content-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.top-searchbar {
  min-width: 200px;
  width: 50%;
  max-width: 100%;
  margin-left: 10px;
}
</style>

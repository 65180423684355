<template>
  <div id="corppage">
    <div class="topText">
      <h1 class="h1">MES RENDEZ-VOUS</h1>
      <b-button
        size="sm"
        class="btnAjouterDispo mt-2 ml-4 mr-4"
        v-if="siPeutMettreDispo"
        @click="ajouterDispo"
        >Créer disponibilité</b-button
      >
      <b-button
        size="sm"
        class="btnAjouterVacances mt-2 ml-4 mr-4"
        v-if="siPeutMettreDispo"
        @click="ajouterVacances"
        >Créer Vacances</b-button
      >
      <!-- <b-button size="sm" class="btnChangerDateMax mt-2 ml-4 mr-4" v-if="siPeutMettreDispo" @click="changerDateMax">Changer date maximale de visibilité des rendez-vous</b-button> -->
      <div id="calendar">
        <vue-scheduler
          :events="events"
          :labels="{
            today: 'Aujourd\'hui',
            back: 'Précédent',
            next: 'Suivant',
            month: 'Mois',
            week: 'Semaine',
            day: 'jour',
            all_day: 'heure',
          }"
          :time-range="[0, 23]"
          :available-views="['week', 'day']"
          initial-view="week"
          :event-display="eventDisplay"
          :disableDialog="true"
          @event-clicked="eventClicked"
          @week-changed="weekChanged"
          @day-changed="dayChanged"
          ref="calendrier"
        />
      </div>
    </div>
    <b-modal id="modal-rvDetail" centered hide-footer :title="eventChoisiTitle">
      <ReservationDetail
        v-bind:eventChoisi="eventChoisi"
        v-bind:eventChoisiType="eventChoisiType"
      />
    </b-modal>
    <b-modal
      id="modal-ajouterDispo"
      centered
      hide-footer
      title="Ajouter disponibilité"
    >
      <DispoSelection />
    </b-modal>
    <b-modal
      id="modal-ajouterVacances"
      centered
      hide-footer
      title="Ajouter Vacances"
    >
      <VacancesSelection />
    </b-modal>
    <!-- <b-modal id="modal-changerDateMax" centered hide-footer title="Changer date visible maximum">
      <BoiteGestionDateMaximaleAffichageDispo />
    </b-modal> -->
    <b-modal
      id="modal-chargement"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
      centered
      hide-footer
      title="Chargement"
    >
      Chargement...
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import ReservationDetail from "@/components/ReservationDetail";
import DispoSelection from "@/components/DispoSelection";
import VacancesSelection from "@/components/VacancesSelection";
// import BoiteGestionDateMaximaleAffichageDispo from '@/components/BoiteGestionDateMaximaleAffichageDispo'
export default {
  name: "ProfilUtilisateur",
  components: {
    ReservationDetail,
    DispoSelection,
    VacancesSelection,
    // BoiteGestionDateMaximaleAffichageDispo
  },
  data() {
    return {
      eventChoisi: null,
      eventChoisiTitle: "",
      eventChoisiType: null,
      eventsRvPris: [],
      eventsDispos: [],
      eventsVacances: [],
      events: [],
      generatedEventsCount: {
        startDate: null,
        count: 0,
      },
      generatedDates: {
        start: null,
        end: null,
      },
      dernierMomentCheckSiNouveau: moment(),
    };
  },
  computed: {
    siPeutMettreDispo() {
      return parseInt(sessionStorage.getItem("role")) === 2;
    },
  },
  methods: {
    modifProfil() {
      history.pushState({}, document.title, window.location.href);
      this.$router.push({ name: "Profil" });
    },
    eventClicked(event) {
      this.eventChoisi = event;
      if (event.client) {
        this.eventChoisiType = "rvAvecClient";
        this.eventChoisiTitle = "Rendez-Vous";
      } else if (event.professionnelId) {
        this.eventChoisiType = "rvAvecProfessionnel";
        this.eventChoisiTitle = "Rendez-Vous";
      } else if (event.type) {
        this.eventChoisiType = "vacances";
        this.eventChoisiTitle = "Vacances";
      } else {
        this.eventChoisiType = "dispo";
        this.eventChoisiTitle = "Disponibilité";
      }
      this.$bvModal.show("modal-rvDetail");
    },
    eventDisplay(event) {
      if (event.client) {
        return event.client;
      } else if (event.type) {
        if (event.type === "VacancesDay") {
          return "Vacances";
        } else if (event.type === "VacancesDebut") {
          return "Vacance \n Début";
        } else if (event.type === "VacancesFin") {
          return "Vacance \n  Fin";
        }
      } else if (event.professionnelId) {
        return event.professionnel;
      }
      return "";
    },
    ajouterDispo() {
      this.$bvModal.show("modal-ajouterDispo");
    },
    ajouterVacances() {
      this.$bvModal.show("modal-ajouterVacances");
    },
    changerDateMax() {
      this.$bvModal.show("modal-changerDateMax");
    },
    weekChanged(event) {
      this.genereEventsSiPossible(moment(event));
    },
    genereEventsSiPossible(nouveauMoment) {
      var needCall = null;
      var start = null;
      var end = null;
      if (
        moment(this.generatedDates.end).subtract(30, "days") <= nouveauMoment
      ) {
        needCall = "after";
        start = moment(this.generatedDates.end);
        end = moment(this.generatedDates.end).add(30, "days");
      }
      if (moment(this.generatedDates.start).add(30, "days") >= nouveauMoment) {
        if (end === null) {
          needCall = "before";
          end = moment(this.generatedDates.start);
        } else {
          needCall = "both";
        }
        start = moment(this.generatedDates.start).subtract(30, "days");
      }
      if (needCall != null) {
        this.getEvents({
          datetime_start: start.startOf("day").format("YYYY-MM-DD HH:mm:ss"),
          datetime_end: end.endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          ignoreVisibiliteMax: true,
        });
        switch (needCall) {
          case "before":
            start.subtract(1, "day");
            this.generatedDates.start = start;
            break;
          case "after":
            end.add(1, "day");
            this.generatedDates.end = end;
            break;
          default:
            start.subtract(1, "day");
            end.add(1, "day");
            this.generatedDates.start = start;
            this.generatedDates.end = end;
            break;
        }
      }
    },
    dayChanged(event) {
      this.genereEventsSiPossible(moment(event));
    },
    generateEvents() {
      this.events = this.eventsRvPris
        .concat(this.eventsDispos)
        .concat(this.eventsVacances);
    },
    incrementGeneratedEventsCount() {
      this.generatedEventsCount.count++;
      var targetNumber = 1;
      if (this.siPeutMettreDispo) {
        targetNumber = 3;
      }
      if (this.generatedEventsCount.count >= targetNumber) {
        this.generateEvents();
      }
    },
    // generateEvents () {
    //   // if(this.generatedDates.start > )
    // },
    getEvents(bodyRequete) {
      this.generatedEventsCount.startDate = bodyRequete["datetime_start"];
      this.generatedEventsCount.count = 0;
      this.$store.dispatch("getRendezVousPris").then((response) => {
        this.eventsRvPris = response["data"];
        this.incrementGeneratedEventsCount();
      });

      if (this.siPeutMettreDispo) {
        this.$store
          .dispatch("getDisposProfessionnel", bodyRequete)
          .then((response) => {
            this.eventsDispos.push(...response["data"]);
            this.incrementGeneratedEventsCount();
          });
        this.$store
          .dispatch("getVacancesProfessionnel", bodyRequete)
          .then((response2) => {
            this.eventsVacances.push(
              ...this.getVacancesDaysCorrectFormatAndOnlyNew(response2["data"])
            );
            this.incrementGeneratedEventsCount();
          });
      }
    },
    getVacancesDaysCorrectFormatAndOnlyNew(response) {
      var vacancesDays = [];
      response.forEach((item) => {
        /*eslint-disable */
        if (vacancesDays.findIndex((vac) => vac["id"] === item["id"]) === -1) {
          const startDate = new Date(item["datetime_start"]);
          const endDate = new Date(item["datetime_end"]);
          vacancesDays.push({
            ...item,
            date: moment(startDate).format("YYYY-MM-DD"),
            startTime: moment(startDate).format("HH:mm:ss"),
            endTime: moment(startDate).add(30, "minutes").format("HH:mm:ss"),
            type: "VacancesDebut",
          });
          vacancesDays.push({
            ...item,
            date: moment(endDate).format("YYYY-MM-DD"),
            startTime: moment(endDate)
              .subtract(30, "minutes")
              .format("HH:mm:ss"),
            endTime: moment(endDate).format("HH:mm:ss"),
            type: "VacancesFin",
          });
          var d = startDate;
          while (d <= endDate) {
            vacancesDays.push({
              ...item,
              date: moment(d).format("YYYY-MM-DD"),
              type: "VacancesDay",
            });
            d.setDate(d.getDate() + 1);
          }
        }
        /* eslint-enable */
      });
      return vacancesDays;
    },
    updateSiNouveauTrucs() {
      var differenceSecondes = Math.round(
        moment
          .duration(moment().diff(this.dernierMomentCheckSiNouveau))
          .asSeconds()
      );
      this.$store
        .dispatch("getIfHasNewMeeting", {
          secondsToCheckBefore: differenceSecondes,
        })
        .then((result) => {
          if (result.data["ifNewMeeting"] === true) {
            this.$toast.open(
              "Vous avez un nouveau rendez-vous, veuillez rafraichir la page",
              { duration: 15000 }
            );
          }
        });
    },
  },
  mounted() {
    setInterval(this.updateSiNouveauTrucs, 15000);
    this.generatedDates.start = moment().startOf("day"); // moment(this.$refs.calendrier.activeDate).day(moment.localeData().firstDayOfWeek()).subtract(7, 'days')
    this.generatedDates.end = moment().startOf("day"); // moment(this.$refs.calendrier.activeDate).day(moment.localeData().firstDayOfWeek()).add(14, 'days')
    this.genereEventsSiPossible(moment().startOf("day"));
  },
  beforeDestroy() {
    clearInterval();
  },
};
</script>

<style>
.v-cal-content .dispo .v-cal-event-name {
  color: rgba(0, 0, 255, 0.7) !important;
}
.rvAvecClient {
  background-color: red !important;
}
.h1 {
  text-align: center !important;
  color: black !important;
}
.dispo {
  background-color: rgba(0, 0, 255, 0.05) !important;
  box-shadow: 0 0 5px rgba(0, 0, 255, 0.5) !important;
}

.v-cal-event-time {
  font-size: 12px !important;
}
.dispo .v-cal-event-time {
  color: black !important;
}
.rvAvecProfessionnel {
  background-color: lightseagreen !important;
}
.vacances {
  background-color: darkmagenta;
}
button:hover {
  background-color: #29689c !important;
}
.v-cal-header__title {
  text-transform: capitalize !important;
}
#corppage {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  margin-top: 10px;
  background-color: white;
}
#corppage h1 {
  font-size: 36px;
  color: #235c8f;
  text-align: left;
  font-family: Roboto;
  font-weight: 900;
  margin-bottom: 20px;
}
#corppage p {
  margin-left: 10vw;
  text-align: left;
}

.topText {
  margin-left: 0;
  margin-bottom: 50px;
}

.left {
  text-align: left;
}

@media screen and (max-width: 1024px) {
  .v-cal-content.v-cal-content--week
    .v-cal-days
    .v-cal-days__wrapper
    .v-cal-event-item,
  .v-cal-content.v-cal-content--day
    .v-cal-days
    .v-cal-days__wrapper
    .v-cal-event-item {
    padding-left: 2px !important;
  }
  .v-cal-content.v-cal-content--week
    .v-cal-days
    .v-cal-days__wrapper
    .v-cal-day__hour-block,
  .v-cal-content.v-cal-content--day
    .v-cal-days
    .v-cal-days__wrapper
    .v-cal-day__hour-block {
    padding: 10px !important;
  }
  .v-cal-content.v-cal-content--week .v-cal-times .v-cal-hour,
  .v-cal-content.v-cal-content--day .v-cal-times .v-cal-hour {
    padding: 10px !important;
  }
  #corppage {
    width: 100%;
  }

  #corppage h1 {
    font-size: 48px;
  }

  .dispo .v-cal-event-time {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 500px) {
  .dispo .v-cal-event-time {
    font-size: 8px !important;
  }
  .v-cal-content.v-cal-content--week
    .v-cal-days
    .v-cal-days__wrapper
    .v-cal-day__hour-block,
  .v-cal-content.v-cal-content--day
    .v-cal-days
    .v-cal-days__wrapper
    .v-cal-day__hour-block {
    padding: 5px !important;
  }
  .v-cal-content.v-cal-content--week .v-cal-times .v-cal-hour,
  .v-cal-content.v-cal-content--day .v-cal-times .v-cal-hour {
    padding: 5px !important;
  }
}

@media screen and (max-width: 400px) {
  .v-cal-content.v-cal-content--week
    .v-cal-days
    .v-cal-days__wrapper
    .v-cal-day__hour-block,
  .v-cal-content.v-cal-content--day
    .v-cal-days
    .v-cal-days__wrapper
    .v-cal-day__hour-block {
    padding: 3px !important;
  }
  .v-cal-content.v-cal-content--week .v-cal-times .v-cal-hour,
  .v-cal-content.v-cal-content--day .v-cal-times .v-cal-hour {
    padding: 3px !important;
  }
}

@media screen and (max-width: 370px) {
  .v-cal-content.v-cal-content--week
    .v-cal-days
    .v-cal-days__wrapper
    .v-cal-day__hour-block,
  .v-cal-content.v-cal-content--day
    .v-cal-days
    .v-cal-days__wrapper
    .v-cal-day__hour-block {
    padding: 2px !important;
  }
  .v-cal-content.v-cal-content--week .v-cal-times .v-cal-hour,
  .v-cal-content.v-cal-content--day .v-cal-times .v-cal-hour {
    padding: 2px !important;
  }
}
</style>

<template>
  <header>
    <section id="header-section-1">
      <div class="header-section-content">
        <div id="section-info-header">
          <a href="tel:4187401730">
            <i class="fa fa-phone" aria-hidden="true"></i>
            +1 (418) 740-1730</a
          >
          <a href="mailto:contact@proressources.ca">
            <i class="fa fa-envelope" aria-hidden="true"></i>
            contact@proressources.ca</a
          >
        </div>
        <div id="section-button-header">
          <a href="https://facebook.com/proressources.ca">
            <img
              border="0"
              alt="facebook icon"
              src="@/assets/facebookIcon.png"
            />
          </a>
          <a href="https://www.instagram.com/proressource.ca/">
            <img border="0" alt="instagram icon" src="@/assets/instagram.png" />
          </a>
          <a href="https://www.linkedin.com/company/pro-ressources-ca/">
            <img border="0" alt="linkedin icon" src="@/assets/linkedIn.png" />
          </a>
          <a href="https://vimeo.com/proressourcesca">
            <font-awesome-icon
              color="white"
              icon="fa-brands fa-vimeo"
              class="icons"
            />
          </a>
          <a
            ref="profilLink"
            @mouseover="profilMouseOver"
            @mouseout="profilMouseOut"
            href="/profil"
          >
            <img border="0" alt="userAccount" src="@/assets/user-alt.png" />
          </a>
        </div>
      </div>
    </section>
    <section id="header-section-2">
      <div class="header-section-content">
        <NavigationBar />
      </div>
    </section>
    <div
      ref="profilInfoContainer"
      :style="{
        top: profilInfoPosition.y + 'px',
        left: profilInfoPosition.x + 'px',
      }"
      class="profilInfoContainer"
    >
      Mon Profil
    </div>
  </header>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";
export default {
  name: "Header",
  components: {
    NavigationBar,
  },
  computed: {
    lienForum() {
      return process.env.VUE_APP_FORUM_LIEN;
    },
  },
  data() {
    return {
      profilInfoPosition: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    profilMouseOver() {
      if (!this.$refs.profilInfoContainer.classList.contains("active")) {
        this.$refs.profilInfoContainer.classList.add("active");
        var lienRectangle = this.$refs.profilLink.getBoundingClientRect();
        var bulleRectangle =
          this.$refs.profilInfoContainer.getBoundingClientRect();
        // La position est ici
        this.profilInfoPosition.x =
          lienRectangle.left - (bulleRectangle.width - lienRectangle.width) / 2;
        this.profilInfoPosition.y = lienRectangle.bottom + 10;
      }
    },
    profilMouseOut() {
      if (this.$refs.profilInfoContainer.classList.contains("active")) {
        // Mettre en commentaire cette ligne là pour tester
        this.$refs.profilInfoContainer.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>
.icons {
  font-size: 18px;
  height: 18px;
  width: 18px;
  vertical-align: middle;
}
.profilInfoContainer {
  position: absolute;
  display: none;
  padding: 10px;
  border-radius: 10px;
  z-index: 300;
  border: 1px solid black;
  background-color: #29689c;
}
.active {
  display: block;
}
header {
  color: white;
  font-size: 15px;
}
header a {
  color: white;
  text-decoration: none;
}

header a:hover {
  color: #ffc90e;
}

#header-section-1 {
  width: 100%;
  height: 55px;
  background-color: #29689c;
}
.header-section-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 8vw;
  padding-right: 8vw; */
  margin: auto;
  height: 100%;
  width: 100%;
  padding-left: 10px;
  max-width: 1500px;
}

#header-section-2 > .header-section-content {
  justify-content: center;
}

#section-info-header {
  width: 450px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.fa {
  margin-right: 0.5vw;
}
.fa-phone {
  transform: rotate(260deg) scale(1.5);
}
.fa-envelope {
  transform: scale(1.2);
}
#section-button-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 180px;
}
#section-button-header img {
  height: 18px;
}
#header-section-2 {
  width: 100%;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

@media screen and (max-width: 1080px) {
  header {
    color: white;
    font-size: 12px;
  }

  #header-section-1 {
    height: 55px;
    padding-left: 2vw;
    padding-right: 2vw;
  }
  #header-section-2 {
    /* height: 60px; */
    display: block;
  }
  #header-section-2 > .header-section-content {
    width: 100%;
  }
}

@media screen and (max-width: 1080px) {
  #section-info-header {
    display: none;
  }
}
</style>

<template>
  <AdminPageWrapper>
    <div class="content">
      <div class="content-top">
        <div class="content-top-top">
          <span>Recherche</span>
          <b-input
            class="top-searchbar"
            type="search"
            v-model="query"
            @input="updateTableData"
          />
        </div>
        <div class="content-top-bottom">
          <b-button :to="{ name: 'pageGestionOrdre' }">
            Ajouter un ordre
          </b-button>
        </div>
      </div>
      <div class="content-middle">
        <b-table ref="ordresTable" :fields="fields" :items="myProvider">
          <template v-slot:cell(logoUrl)="row">
            <img :src="imgFirstPartLink + row.item.logoUrl" class="tblImg" />
          </template>
          <template #cell(edit)="data">
            <b-button
              :to="{ name: 'pageGestionOrdre', params: { id: data.item.id } }"
            >
              Modifier
            </b-button>
          </template>
          <template #cell(delete)="data">
            <b-button variant="danger" @click="deleteItem(data.item.id)">
              Supprimer
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
  </AdminPageWrapper>
</template>

<script>
import AdminPageWrapper from "@/components/AdminPageWrapper.vue";
export default {
  name: "ListeOrdres",
  components: {
    AdminPageWrapper,
  },
  computed: {
    imgFirstPartLink() {
      return process.env.VUE_APP_BACKEND_STORAGE_URL;
    },
  },
  methods: {
    updateTableData() {
      this.$refs.ordresTable.refresh();
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm("Voulez-vous vraiment supprimer l'ordre?", {
          title: "Supprimer",
          cancelTitle: "Non",
          okTitle: "Oui",
        })
        .then((canDelete) => {
          if (canDelete) {
            this.$store
              .dispatch("deleteOrdre", id)
              .then(() => {
                this.$toast.success("Ordre supprimé avec succès", {
                  duration: 5000,
                });
                this.isOrdreLoaded = false;
                this.updateTableData();
              })
              .catch((err) => {
                console.log(err.message.data);
              });
          } else {
            this.$toast.error("Suppression annulée", { duration: 5000 });
          }
        })
        .catch((error) => {
          this.$toast.error(
            error.response.data.message ||
              "Erreur lors de la suppression de l'ordre",
            { duration: 5000 }
          );
        });
    },
    myProvider(ctx, callback) {
      const whenLoaded = () => {
        const query = this.query;
        const itemsWithQuery = this.ordres.filter(
          (element) => element.nom.includes(query) || query === ""
        );
        callback(itemsWithQuery);
      };
      if (!this.isOrdreLoaded) {
        this.$store
          .dispatch("getAllOrdres")
          .then((ordres) => {
            this.ordres = ordres;
            this.isOrdreLoaded = true;
            whenLoaded();
          })
          .catch(() => {
            callback();
          });
      } else {
        whenLoaded();
      }
      return null;
    },
  },
  data() {
    return {
      isOrdreLoaded: false,
      ordres: [],
      query: "",
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "nom",
          sortable: true,
        },
        {
          key: "logoUrl",
          label: "Logo",
        },
        {
          key: "edit",
          label: "Modifier",
        },
        {
          key: "delete",
          label: "Supprimer",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* >>> button {
  background-color: #29689c !important;
} */
.tblImg {
  width: 50px;
  height: 50px;
}
.content-middle {
  display: flex;
  flex-direction: column;
  width: 99vw;
  align-items: flex-start;
  background-color: white;
}
.content-top-top {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.content-top-bottom {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.content-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.top-searchbar {
  min-width: 200px;
  width: 50%;
  max-width: 100%;
  margin-left: 10px;
}
</style>

<template>
  <div>
    Date <b-form-input type="date" name="date" v-model="date" />
    <br />
    Heure début
    <b-form-timepicker locale="fr-ca" name="timeStart" v-model="timeStart" />
    <br />
    Heure Fin
    <b-form-timepicker locale="fr-ca" name="timeEnd" v-model="timeEnd" />
    <br />
    À chaque semaines
    <input type="checkbox" id="siHebdomadaire" v-model="siHebdomadaire" />
    <br />
    <div v-if="siHebdomadaire">
      Date de fin (inclusive)
      <b-form-input type="date" name="dateFin" v-model="dateFin" />
    </div>
    <span v-if="erreur" class="erreur">{{ erreur }}</span>
    <br />
    <b-button class="buttonSoumettre" type="submit" @click="creerDispos()"
      >Créer</b-button
    >
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "DispoSelection",
  data() {
    return {
      date: null,
      dateFin: null,
      timeStart: null,
      timeEnd: null,
      siHebdomadaire: false,
      erreur: null,
    };
  },
  computed: {
    getProfessionnelInfoBodyJour() {
      var startDatetime = moment(this.date)
        .subtract(2, "days")
        .format("YYYY-MM-DD HH:mm:ss");
      var endDatetime = moment(this.date)
        .add(2, "days")
        .format("YYYY-MM-DD HH:mm:ss");
      return {
        datetime_start: startDatetime,
        datetime_end: endDatetime,
        ignoreVisibiliteMax: false,
      };
    },
  },
  methods: {
    chercheSiDispoParDessusUneAutre() {
      return new Promise((resolve, reject) => {
        var siPeutCreerDispo = true;
        var newDispoStart = moment(
          this.date + " " + this.startTime,
          "YYYY-MM-DD HH:mm:ss"
        );
        var newDispoEnd = moment(
          this.date + " " + this.endTime,
          "YYYY-MM-DD HH:mm:ss"
        );
        this.$store
          .dispatch("getDisposProfessionnel", this.getProfessionnelInfoBodyJour)
          .then((response) => {
            response["data"].forEach((dispoItem) => {
              var dispoItemStart = moment(
                dispoItem["date"] + " " + dispoItem["startTime"],
                "YYYY-MM-DD HH:mm:ss"
              );
              var dispoItemEnd = moment(
                dispoItem["date"] + " " + dispoItem["endTime"],
                "YYYY-MM-DD HH:mm:ss"
              );
              if (
                newDispoStart <= dispoItemStart &&
                newDispoEnd >= dispoItemStart
              ) {
                siPeutCreerDispo = false;
              } else if (
                newDispoStart > dispoItemStart &&
                newDispoStart <= dispoItemEnd
              ) {
                siPeutCreerDispo = false;
              }
            });
            resolve(siPeutCreerDispo);
          });
      });
    },
    creerDispos() {
      this.erreur = null;
      if (moment(this.timeStart, "HH:mm") < moment(this.timeEnd, "HH:mm")) {
        if (
          (this.siHebdomadaire && moment(this.date) < moment(this.dateFin)) ||
          !this.siHebdomadaire
        ) {
          this.$bvModal.show("modal-chargement");
          var dispos = {
            isHebdomadaire: this.siHebdomadaire,
          };
          if (!this.siHebdomadaire) {
            dispos["datetime_start"] =
              moment(this.date).format("YYYY-MM-DD") +
              " " +
              moment(this.timeStart, "HH:mm").format("HH:mm:ss");
            dispos["datetime_end"] =
              moment(this.date).format("YYYY-MM-DD") +
              " " +
              moment(this.timeEnd, "HH:mm").format("HH:mm:ss");
          } else {
            dispos["time_start"] = this.timeStart;
            dispos["time_end"] = this.timeEnd;
            dispos["datetime_end"] = moment(this.dateFin)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss");
            dispos["datetime_start"] = moment(this.date)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss");
            dispos["noJourneeSemaine"] = moment(this.date).weekday();
          }
          this.$store
            .dispatch("creerDisposProfessionnel", dispos)
            .then((response) => {
              this.$bvModal.hide("modal-chargement");
              location.reload();
            })
            .catch((error) => {
              this.$bvModal.hide("modal-chargement");
              console.log(error);
              this.erreur = "Il y a déjà une disponibilité à cet endroit";
            });
        } else {
          this.$bvModal.hide("modal-chargement");
          this.erreur = "La date de fin doit être après la date de début";
        }
      } else {
        this.$bvModal.hide("modal-chargement");
        this.erreur = "L'heure de début doit être avant l'heure de fin";
      }
    },
  },
};
</script>

<style scoped>
.erreur {
  color: red;
}
</style>
